/* LAYOUT */

@media (max-width: 576px) {
  .form-group .col {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

/* FONT */

body {
  font-family: "WorkSans", "Segoe UI", "Source Sans Pro", Calibri, Candara, Arial, sans-serif;
}

/* TABLE */

.table {
  background-color: #fff;
  margin-bottom: 0;
}

/* PAGINATION */

.pagination {
  margin: 0;
}

.page-link {
  color: #26afa8;
}

.page-link:hover {
  color: #26afa8;
}

.page-item.active .page-link {
  background-color: #26afa8;
  border-color: #26afa8;
}

/* NAV-LINK */

.nav-pills .nav-link {
  color: #26afa8;
  display: block;
  padding: 0.5rem 1rem;
}

.nav-pills .nav-link:focus {
  border: none;
}

.nav-pills .nav-link.active {
  color: #fff;
  background-color: #26afa8;
}

/* BUTTONS */

.btn {
  font-family: 'WorkSans-SemiBold';
  font-style: normal;
  border: none;
}

.btn:hover {
  border: none;
}

.btn:focus, .btn.focus {
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn.disabled, .btn:disabled {
  border-color: none;
}

.btn-primary {
  color: #fff;
  background-color: #53963e;
}

.btn-primary:hover {
  color: #fff;
  background-color: #468034;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #53963e;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #53963e;
  border-color: none;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #53963e;
  border: none;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-delete {
  color: #fff;
  background-color: #FF0039;
  border-color: #FF0039;
}

.btn-delete:hover {
  color: #fff;
  background-color: #d90030;
  border-color: #cc002e;
}

.btn-delete:focus, .btn-delete.focus {
  color: #fff;
  background-color: #d90030;
  border-color: #cc002e;
}

.btn-delete.disabled, .btn-delete:disabled {
  color: #fff;
  background-color: #FF0039;
  border-color: #FF0039;
}

.btn-delete:not(:disabled):not(.disabled):active, .btn-delete:not(:disabled):not(.disabled).active,
.show > .btn-delete.dropdown-toggle {
  color: #fff;
  background-color: #cc002e;
  border-color: #bf002b;
}

.btn-link {
  padding: 0;
}

.btn-search {
  color: #000;
  font-family: "WorkSans";
  text-decoration: underline;
  padding: 0;
}

.btn-search:hover {
  color: #000;
}

.btn-search:focus, .btn-search.focus {
  color: #000;
}

.btn-search.disabled, .btn-search:disabled {
  color: #000;
}

.btn-search:not(:disabled):not(.disabled):active, .btn-search:not(:disabled):not(.disabled).active,
.show > .btn-search.dropdown-toggle {
  color: #000;
}

.btn-search:not(:disabled):not(.disabled):active:focus, .btn-search:not(:disabled):not(.disabled).active:focus,
.show > .btn-search.dropdown-toggle:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

/* FORM */

.form-control:focus {
  border-color: #53963e;
  box-shadow: none;
}

.col-form-label-lg {
  font-family: 'Worksans-Medium'
}

/* PAGINATION */

.page-item.page-num {
  text-align: center;
  width: 44px;
}

/* CUSTOM */

.logo {
  width: 224.55px;
}

.app-col {
  padding: 0px 60px;
}

.pm-text {
  text-align: right;
}
@media (max-width: 576px) {
  .pm-text {
    text-align: left;
  }
}

.label {
  max-width: 100px;
}

.analogy-text {
  text-align: center;
  padding-top: 10px;
}
@media (max-width: 576px) {
  .analogy-text {
    padding: 10px 0px;
  }
}

.searches {
  margin: 10px 0px;
}

.results-msg {
  font-size: 18.75px;
  text-align: center;
}

.right-btn-container {
  text-align: right;
}
@media (max-width: 576px) {
  .right-btn-container {
    text-align: left;
  }
}

#header {
  background-color: black;
  padding: 1.4vw 4vw;
  margin: 0;
}

@media (min-width: 576px) {
  #header {
    max-height: 87px;
  }
}

#logo {
  width: auto;
  max-width: 100%;
  max-height: 50px;
  position: absolute;
  top: 18px;
  left: 50px;
}

@media (max-width: 576px) {
  #logo {
    height: 0px;
  }
}

@media (min-width: 576px) {
  #logo-mobile {
    height: 0px;
  }
}

#logo-container {
  padding-top: 15px;
}
@media (min-width: 576px) {
  #logo-container {
    height: 0px;
    padding-top: 0px;
  }
}

#title {
  color: white;
  font-family: 'Adieu';
  font-style: bold;
  font-size: 40px;
  padding: 0;
}
@media (min-width: 576px) {
  #title {
    top: -10px;
  }
}

#description {
  color: black;
  padding: 30px 60px;
}

#app-container {
  min-height: 442px;
  padding-bottom: 20px;
}

#tabs-container {
  margin-bottom: 10px;
}

#form-container {
  margin: 20px 10px;
}

#search-title {
  font-family: "WorkSans-SemiBold";
  font-size: 16px;
  padding: 0;
}

#empty-results {
  background: #53963e;
  width: 50vw;
  height: 50vh;
}

#results-heading {
  padding: 0px;
  margin-bottom: 15px;
}

#results-text {
  font-family: 'WorkSans-Medium';
  font-size: 18.75px;
}

#download {
  font-family: 'WorkSans-Medium';
  font-size: 12px;
  text-align: right;
  vertical-align: bottom;
  padding-top: 10px;
  min-width: 105px
}

#results-table {
  overflow: auto;
  margin-bottom: 1rem;
  padding: 0;
  max-height: 50vh;
  border: 1px solid #dee2e6;
}

#tutorial-container {
  /* background-color: #26afa8; */
}

#links-container {
  padding: 20px;
  background-color: #000;
  color: #fff;
  position: relative;
  bottom: 0;
}

#bsf-container {
  /* background-color: #53963e; */
}
