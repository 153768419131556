@font-face {
  font-family: 'Adieu';
  font-style: bold;
  src: local('Adieu-Bold'), url(./fonts/Adieu-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'WorkSans';
  src: local('WorkSans-Regular'), url(./fonts/WorkSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'WorkSans';
  font-style: italic;
  src: local('WorkSans-Italic'), url(./fonts/WorkSans-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'WorkSans-Black';
  src: local('WorkSans-Black'), url(./fonts/WorkSans-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'WorkSans-Bold';
  src: local('WorkSans-Bold'), url(./fonts/WorkSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'WorkSans-ExtraLight';
  src: local('WorkSans-ExtraLight'), url(./fonts/WorkSans-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'WorkSans-Light';
  src: local('WorkSans-Light'), url(./fonts/WorkSans-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'WorkSans-Medium';
  src: local('WorkSans-Medium'), url(./fonts/WorkSans-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'WorkSans-SemiBold';
  src: local('WorkSans-SemiBold'), url(./fonts/WorkSans-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'WorkSans-Thin';
  src: local('WorkSans-Thin'), url(./fonts/WorkSans-Thin.ttf) format('truetype');
}
